
.size-visualization canvas:hover {
    outline: none;
  }
  
  .size-visualization .canvas-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 16:9 Aspect Ratio */
  }
  
  .size-visualization #canvas3d {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none;
  }
  
  .size-visualization .disabled img {
    opacity: 0.5;
  }
  
  .size-visualization .btn-secondary {
    margin: 0 4px;
  }

  .mzr_pictofit_sizevisualizer_modal_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

// The modal "window"
.mzr_pictofit_sizevisualizer_modal {
    background-color: rgb(255, 255, 255);
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 80%;
    display: inline-block;
}

.mzr_pictofit_sizevisualizer_modal_button {
    @include primary-button(var(--msv-accent-brand-color),
    var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
    font-size: var(--msv-buybox-btn-font-size);
    line-height: $msv-line-height-l;
    flex-grow: 1;
}

.mzr_pictofit_sizevisualizer_launch_button {
    @include primary-button(var(--msv-accent-brand-color));
    font-size: var(--msv-buybox-btn-font-size);
    line-height: $msv-line-height-l;
    flex-grow: 1;
}

.mzr_pictofit_sizevisualizer_launch_button_hide {
  display: none;
}

.mzr_pictofit_sizevisualizer_launch_button:hover {
    background-color: var(--msv-accent-brand-alt-color);
}

.mzr_pictofit_sizevisualizer_launch_button:focus {
    background-color: var(--msv-accent-brand-color);
    @include basic-outline-offset(1px, dashed, $msv-black, 0px);
}


.mzr_pictofit_sizevisualizer_modal #canvas-main {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.mzr_pictofit_sizevisualizer_modal .btn-link {
  border-radius: 15px;
  padding: 10px;
  background-color: #fff;
  margin: 4px 0;
  border: 1px solid black;
  outline: none;
}