
.personalised-mannequin .material-selector {
    border-radius: 50%;
    border: 1px solid #111;
    width: 40px;
    height: 40px;
    display: inline-block;
    padding: 1px;
    margin: 0px 5px;
  }
  .personalised-mannequin .material-selector div {
    display: inline-block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
  
  .personalised-mannequin canvas:hover {
    outline: none;
  }
  
  .personalised-mannequin .canvas-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 16:9 Aspect Ratio */
  }
  
  .personalised-mannequin #canvas3dPersonalized {
    position: absolute;
    top: 0;
    width: 500px;
    height: 500px;
    outline: none;
  }