.mzr_customer_request_assistance_modal_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

  // The modal "window"
.mzr_customer_request_assistance_modal_modal {
    background-color: #fff;
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    display: block;
}

.mzr_customer_request_assistance_modal_button {
    padding: 10px;
    margin: 1px;
    position: relative;
    right: 0;
    bottom: 0;
}

.mzr_customer_request_assistance_modal_inputText {
    width: 550px;
    height: 100px;
    display: block;
}