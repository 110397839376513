$mzr_pictofit_virtualtryon2d_modal_button_item-outline-width: 1px;
$mzr_pictofit_virtualtryon2d_modal_button_item-outline-style: dashed;
$mzr_pictofit_virtualtryon2d_modal_button_item-outline-offset:0px;

:root {
    background-color: rgba(0,0,0,0.2);
}

.mzr_pictofit_virtualtryon2d_modal_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

// The modal "window"
.mzr_pictofit_virtualtryon2d_modal {
    background-color: rgb(255, 255, 255);
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 80%;
    display: inline-block;
}

.mzr_pictofit_virtualtryon2d_modal_button {
    @include primary-button(var(--msv-buybox-primary-btn-bg),
    var(--msv-buybox-primary-btn-font-color), var(--msv-buybox-primary-btn-border));
    font-size: var(--msv-buybox-btn-font-size);
    line-height: $msv-line-height-l;
    flex-grow: 1;
}

.mzr_pictofit_virtualtryon2d_launch_button {
    @include primary-button(var(--msv-accent-brand-color));
    font-size: var(--msv-buybox-btn-font-size);
    line-height: $msv-line-height-l;
    flex-grow: 1;
}

.mzr_pictofit_virtualtryon2d_launch_button_hide {
    display: none;
  }

.mzr_pictofit_virtualtryon2d_launch_button:hover {
    background-color: var(--msv-accent-brand-alt-color);
}

.mzr_pictofit_virtualtryon2d_launch_button:focus {
    background-color: var(--msv-accent-brand-color);
    @include basic-outline-offset(1px, dashed, $msv-black, 0px);
}

.mzr-column {
    display: grid;
    grid-template-columns: auto;
}


.mzr_pictofit_virtualtryon2d_modal #canvas-main {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.mzr_pictofit_virtualtryon2d_modal .btn-link {
  border-radius: 15px;
  padding: 10px;
  background-color: #fff;
  margin: 4px 0;
  border: 1px solid black;
  outline: none;
}

.mzr_pictofit_virtualtryon2d_inline_modal {
    background-color: rgb(255, 255, 255);
    position: relative;       
    display: block;
}


.mzr_pictofit_virtualtryon2d_inline_modal #canvas2dinline {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    outline: none;
    min-width: 500px;
    min-height: 500px;
  }

  .mzr_pictofit_virtualtryon2d_inline_modal_backdrop {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);    
    display: flex;
    justify-content: center;
    align-items: center;
}

.mzr_pictofit_virtualtryon2d_inline_modal .btn-link {
    border-radius: 15px;
    padding: 10px;
    background-color: #fff;
    margin: 4px 0;
    border: 1px solid black;
    outline: none;
  }