;
@import "@msdyn365-commerce-modules/fabrikam-design-kit/dist/lib/modules/fabrikam/styles/fabrikam.theme.scss";
@import "bootstrap/scss/bootstrap";
@import "mzr-fabrikam-extension-styles/index.scss";

$brandPrimaryColor: #FFFFFF;
$brandSecondaryColor: #CCCCCC;
$backgrounDefaultColor: #FFFFFF;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;


:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgrounDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
}

body {
    background-color: var(--backgroundDefaultColor);
}

$mzr_pictofit_virtualtryon3d_modal_button_item-outline-width: 1px;
$mzr_pictofit_virtualtryon3d_modal_button_item-outline-style: dashed;
$mzr_pictofit_virtualtryon3d_modal_button_item-outline-offset:0px;